<script setup lang="ts">
const user = useCurrentUser();
const { clearAuthState } = useCurrentUserDetails();
const { $sentry } = useNuxtApp();

const onLogout = async () => {
  try {
    await logout();
    clearAuthState();
    await navigateTo('/login');
  } catch (error) {
    $sentry.captureException(error);
    console.error(error);
  }
};
</script>

<template>
  <div
    class="bg-onboarding-background relative z-0 flex min-h-screen flex-col items-center justify-center bg-cover bg-center bg-no-repeat px-6 py-24"
  >
    <div
      class="relative z-20 mx-6 flex min-h-[30rem] w-full max-w-[640px] flex-col justify-between rounded-3xl border-b-4 border-l border-r border-t border-black bg-white p-6 sm:p-8 md:rounded-2xl"
    >
      <div class="flex flex-col items-center">
        <slot name="header" />
        <slot name="content" />
      </div>
      <div class="flex flex-col items-center">
        <slot name="footer" />
      </div>
    </div>
    <div
      v-if="user?.email"
      class="mt-4 space-y-2 text-center text-sm text-black text-opacity-80"
    >
      <p>
        Logged in as: <strong>{{ user.email }}</strong>
      </p>
      <button class="underline" @click="onLogout">Logout</button>
    </div>
  </div>
</template>
